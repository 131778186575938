
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Kutubee",
  components: {},

  setup() {
    const { t, locale } = useI18n();

    // Set the i18n locale to French
    locale.value = "fr";
    const route = useRoute();
    let orderId = ref("");
    onMounted(() => {
      setCurrentPageTitle(t("onlinePayment.success"));
      const paymentRef = route.query.payment_ref;
      const dbName = route.params.dbName;

      ApiService.setHeaderBuilding(String(dbName));
      ApiService.get("/konnect/status/" + paymentRef)
        .then(({ data }) => {
          orderId.value = data.orderId;
        })
        .catch((e) => console.log(e));
    });

    return { orderId };
  },
});
